<template>
  <section
    id="counseling"
    class="te-banner-briefing-htmlcss-trial"
  >
    <div class="co-container-md mx-auto px-4 py-10 md:py-12">
      <!-- キャッチコピー -->
      <div v-if="isVisibleCatch">
        <slot name="catch">
          <div class="co-display-13 mb-7 flex justify-center text-center font-bold text-co-primary-500">
            <p class="te-circle mb-4 mr-1.5 flex items-center justify-center rounded-full bg-white md:mr-3">
              本当に
              <br>
              副業できるの？
            </p>
            <p class="te-circle mx-3 mb-3 flex items-center justify-center rounded-full bg-white">
              どのコースを
              <br>
              選べば良いの？
            </p>
            <p class="te-circle mb-4 ml-1.5 flex items-center justify-center rounded-full bg-white md:ml-3">
              他スクールとの
              <br>
              違いは？
            </p>
          </div>
        </slot>
      </div>
      <h2 class="co-display-7 mb-6 text-center font-bold">
        <span
          class="co-display-13 mr-2 inline-block rounded p-1 font-bold leading-none text-white"
          :style="{ backgroundColor: '#0F89A3' }"
        >
          スマホ
          <br>
          OK
        </span>無料で相談する
      </h2>
      <!-- リード文 -->
      <div class="flex flex-col flex-wrap items-center md:mb-6 md:flex-row">
        <div class="mb-6 w-full flex-1 text-center md:mb-0 md:w-1/2 md:flex-none md:px-4 md:text-right lg:w-[41.5%]">
          <img
            class="te-image-mentoring mx-auto md:mr-0"
            src="~/assets/images/Te/TeCommon/Banner/BriefingHtmlcssTrial/img_mentoring.svg"
            alt="無料で相談する"
            width="299"
            height="186"
            loading="lazy"
          >
        </div>
        <div class="w-full flex-1 text-center md:w-1/2 md:flex-none md:px-4 md:text-left lg:w-[38%]">
          <p class="mb-4">
            <slot name="lead">
              高いスキルとコミュニケーション力でお悩みに<br class="md:hidden">何でもズバッとお答えします。
            </slot>
          </p>
          <img
            src="~/assets/images/Te/TeCommon/Banner/BriefingHtmlcssTrial/banner_amazon-gift.svg"
            alt="今相談すると、先着500名様限定にAmazonギフト券500円分プレゼント"
            width="300"
            height="126"
            loading="lazy"
            class="mx-auto md:mx-0"
          >
        </div>
      </div>
      <!-- 日程表 -->
      <div v-if="scheduleApply">
        <TeCommonBannerBriefingHtmlcssTrialScheduleApply
          :appointment-form="appointmentForm"
          :appointment-form-table="appointmentFormTable"
        />
        <div class="mt-9 text-center">
          <p class="co-display-13 mb-2">
            ＼ 相談したい職種も選べます ／
          </p>
          <a
            class="bot_open co-btn-counseling"
            href="/counseling/htmlcss-schedule#schedule"
          >
            1週間分の日程を確認する
            <FontAwesomeIcon
              :icon="faAngleRight"
              class="fa-fw inline h-4 w-5"
            />
          </a>
        </div>
      </div>
      <!-- 下部ボタンエリア -->
      <div
        v-else
        class="text-center"
      >
        <h3 class="co-display-10 mb-3 mt-7 font-bold md:mt-8">
          迷っている方は気軽に相談
        </h3>
        <a
          class="bot_open co-btn-counseling mb-3"
          href="/counseling/lp/htmlcss-schedule"
        >
          無料で相談してみる
          <FontAwesomeIcon
            :icon="faAngleRight"
            class="fa-fw inline h-4 w-5 pl-2"
          />
        </a>
        <p class="mb-0 text-co-gray-500">
          <span class="mb-1 inline-block">
            当日1時間後からOK（8:00〜24:00）
          </span>
          <br>
          無理な勧誘は一切ありません。
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {
  getTrialMiniCourseSchedule,
  getTrialMiniCourseScheduleTable,
} from '~/utility/fetchAPI'

import TeCommonBannerBriefingHtmlcssTrialScheduleApply from '~/components/Te/TeCommon/Banner/TeCommonBannerBriefingHtmlcssTrial/TeCommonBannerBriefingHtmlcssTrialScheduleApply.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleRight } from '@awesome.me/kit-65d95819ce/icons/classic/solid'

interface Data {
  appointmentForm: any
  appointmentFormTable: any
}

const options = {
  asyncData() {
    return {
      faAngleRight,
    }
  },
  props: {
    scheduleApply: { // 日程表の表示/非表示
      default: false,
      type: Boolean,
    },
    isVisibleCatch: { // catchの表示/非表示
      default: true,
      type: Boolean,
    },
  },
  data():Data {
    return {
      appointmentForm: null,
      appointmentFormTable: null,
    }
  },
  mounted() {
    getTrialMiniCourseSchedule(this.$api, 'htmlcss-trial').then((response) => {
      this.appointmentForm = response
    })
    const body: any = {
      section: true,
    }
    getTrialMiniCourseScheduleTable(this.$api, 'htmlcss-trial', body).then(
      (response) => {
        this.appointmentFormTable = response
      }
    )
  },
  components: {
    TeCommonBannerBriefingHtmlcssTrialScheduleApply,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-banner-briefing-htmlcss-trial {
  background-color: #deeff0;
}

.te-circle {
  width: 6.625rem;
  height: 6.625rem;
  @media (min-width: 768px) {
    width: 7.75rem;
    height: 7.75rem;
  }
}

.te-image-mentoring {
  @media (max-width: 767.98px) {
    width: 17.5rem;
    height: 10.875rem;
  }
}
</style>
