<template>
  <div>
    <!-- メンターのメッセージ動画 -->
    <div
      id="mentor-movie"
      class="mt-10 hidden"
    >
      <div
        class="mx-auto bg-white"
        style="max-width: 680px"
      >
        <div class="flex w-full flex-col items-center sm:flex-row">
          <div class="sm:w-1/3">
            <iframe
              class="te-mentor-movie aspect-video w-full"
              src="//www.youtube.com/embed/N8XTRdl5UDM?rel=0&amp;controls=0&amp;showinfo=0"
              frameborder="0"
              width="100%"
              allowfullscreen="true"
            />
          </div>
          <div class="p-5 sm:w-2/3 sm:py-6">
            <div class="co-display-10 mb-3 text-center font-bold leading-none sm:text-left">
              こんなメンターに相談できます！
            </div>
            <p class="mb-0">
              無料相談担当の1人、石津メンターからの一言メッセージ
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 予約に関する案内 -->
    <div class="mb-5 mt-12 flex justify-center">
      <div class="mr-4 flex items-center md:mx-6">
        <div class="te-firststep-icon mr-2 flex items-center justify-center rounded-full border-2 border-co-primary-500 bg-white">
          <FontAwesomeIcon
            :icon="faLightbulb"
            class="fa-fw co-display-10 inline h-5 w-3 text-co-primary-500"
          />
        </div>
        <div class="te-firststep-text text-left font-bold">
          当日1時間後から
          <br>
          予約OK
        </div>
      </div>
      <div class="mr-4 flex items-center md:mx-6">
        <div class="te-firststep-icon mr-2 flex items-center justify-center rounded-full border-2 border-co-primary-500 bg-white">
          <FontAwesomeIcon
            :icon="faClockNine"
            class="fa-fw co-display-10 inline h-5 w-4 text-co-primary-500"
          />
        </div>
        <div class="te-firststep-text text-left font-bold">
          8:00〜24:00まで
          <br>
          選択OK
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLightbulb, faClockNine } from '@awesome.me/kit-65d95819ce/icons/classic/regular'
</script>

<script lang="ts">
import { daysOfWeek } from '~/utility/utility'

interface Data {
  appointmentDate: Date | null
}

interface Methods {
  create2daysArray: (date: Date | null) => Date[] | null[]
  sendAppointmentDate: (time: string, index: number) => void
  dateToStr: (date: Date) => string
}

interface Computed {
  table: boolean[][]
  appointmentDays: Date[] | null[]
  appintmentTimes: (hour: number) => any
  appointmentTable: boolean[][]
}

interface Props {
  appointmentForm: any
  appointmentFormTable: any
}

const options = {
  props: {
    appointmentForm: {
      default: null,
    },
    appointmentFormTable: {
      default: null,
    },
  },
  data() {
    return {
      appointmentDate: null,
    }
  },
  mounted() {},
  computed: {
    table() {
      if (!this.appointmentFormTable) return null
      return this.appointmentFormTable.table
    },
    appointmentDays() {
      if (!this.appointmentForm) return this.create2daysArray(null)
      const submittedBeginsAtDate = new Date(
        this.appointmentForm.submitted_begins_at
      )
      return this.create2daysArray(submittedBeginsAtDate)
    },
    appintmentTimes() {
      return function (hour) {
        const rangeFrom0To31 = Array.from(Array(32), (_, k) => k)
        const times = rangeFrom0To31.map((num, i) => {
          if (i % 2 === 0) {
            const n = 8 + num / 2
            if (n === hour) {
              return {
                num,
                time: `${n}:00`,
              }
            } else {
              return {}
            }
          } else {
            const n = Math.floor(8 + (num - 1) / 2)
            if (n === hour) {
              return {
                num,
                time: `${n}:30`,
              }
            } else {
              return {}
            }
          }
        })
        return times.filter((obj) => Object.keys(obj).length)
      }
    },
    appointmentTable() {
      if (!this.table) {
        const initialValue = [...Array(32)].map(() => {
          return [...Array(2)].map(() => {
            return false
          })
        })
        return initialValue
      } else {
        return this.table
      }
    },
  },
  methods: {
    create2daysArray(date: Date | null): Date[] | null[] {
      if (!date) return [...Array(2)].map(() => null)
      const today = new Date(date) as Date
      if (today.getHours() >= 22) {
        today.setDate(today.getDate() + 1)
      }
      const days = [...Array(2)].map((_, index) => {
        const day = new Date()
        day.setDate(today.getDate() + index)
        day.setHours(0)
        day.setMinutes(0)
        day.setSeconds(0)
        return day
      })
      return days
    },
    sendAppointmentDate(time, index) {
      const day = this.appointmentDays[index]
      if (!day) return null
      const [hour, minute] = time.split(':')
      const date = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        Number(hour),
        Number(minute)
      )
      this.appointmentDate = date
    },
    dateToStr(date) {
      if (date == null) return '-/- (-)'
      return `${date.getMonth() + 1}/${date.getDate()} (${daysOfWeek(date)})`
    },
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-firststep-icon {
  min-width: 2.25rem;
  max-width: 2.25rem;
  min-height: 2.25rem;
  max-height: 2.25rem;
}

.te-firststep-text {
  line-height: 1.5;
  color: #1f7f96;
}

/* 申し込み 日程表 */
.te-schedule-date {
  @media (min-width: 992px) {
    width: 7.5rem;
  }
}

.te-schedule-apply-hour {
  .te-schedule-apply-button {
    width: 8rem;
    &:first-of-type {
      margin-right: 0.25rem;
    }
  }
  .te-no-select {
    font-size: 0;
    cursor: auto;
    opacity: 0.3;
    &:active {
      color: #333;
    }
    &::before {
      font-size: 1rem;
      content: '空席なし';
    }
  }
}

/* メンターのメッセージ動画 */
.te-mentor-movie {
  height: 12rem;
  vertical-align: bottom;
  @media (min-width: 576px) {
    height: 8.5rem;
  }
}
</style>
